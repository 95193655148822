<template>
  <div>
    <DetailRow>
      <template #name> Top </template>
      <template #extra>
        <NoteLinks :notes="notes.top" />
      </template>
    </DetailRow>
    <DetailRow>
      <template #name> Middle </template>
      <template #extra>
        <NoteLinks :notes="notes.middle" />
      </template>
    </DetailRow>
    <DetailRow>
      <template #name> Base </template>
      <template #extra>
        <NoteLinks :notes="notes.base" />
      </template>
    </DetailRow>
  </div>
</template>

<script>
  import DetailRow from "../DetailRow"

  export default {
    components: { DetailRow },
    props: {
      notes: {
        type: Object,
        default: () => {},
      },
    },
  }
</script>
