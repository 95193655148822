<template>
  <div class="wrap">
    <DetailRow v-for="prop in properties" :key="prop.name" alignment="center">
      <template #name>
        <template v-if="prop.type === 'Boolean'">
          <span class="prop-name">
            <span class="at-text">
              {{
                prop.value
                  ? `This product is ${prop.label}`
                  : `This product is not ${prop.label}`
              }}
            </span>
            <span aria-hidden="true"> {{ prop.label }} </span>
          </span>
        </template>
        <span v-else>
          {{ prop.label }}
        </span>
      </template>
      <template #note>
        <span :aria-hidden="!prop.value" class="prop-note">
          {{ prop.note }}
        </span>
      </template>

      <template #extra>
        <template v-if="prop.label === 'Candle Safe' && prop.value === false">
          <span class="property-table__candle-safe"> Not recommended</span>
        </template>
        <template v-else-if="prop.label === 'Prop 65 Warning Required'">
          <span class="property-table__prop-warning-required">
            {{ prop.value ? "Yes" : "No" }}
          </span>
        </template>
        <template v-else-if="prop.type === 'Boolean'">
          <SvgIcon
            v-if="prop.value === true"
            icon="form-check"
            class="detailcheckicon"
            aria-hidden="true"
          />
          <SvgIcon
            v-if="!prop.value"
            icon="x"
            class="markicon"
            aria-hidden="true"
          />
        </template>
        <template v-else-if="prop.type === 'Rating'">
          <div class="leafrating" :title="`Rating: ${prop.value} out of 3`">
            <span class="at-text"> {{ prop.value }} of 3 </span>
            <SvgIcon
              v-for="(leaf, i) in [...Array(3)]"
              :key="leaf"
              icon="leaf"
              aria-hidden="true"
              :class="['leaficon', prop.value > i && 'leaficon-active']"
            />
          </div>
        </template>
        <template
          v-else-if="prop.label === 'Flashpoint' && prop.type === 'String'"
        >
          <span class="flashpoint">
            {{ prop.value }}
          </span>
        </template>
        <template
          v-else-if="
            prop.label === 'Vanillin Content' && prop.type === 'String'
          "
        >
          <span class="percent">
            {{ prop.value }}
          </span>
        </template>
        <template v-else>
          <span :class="{ 'italic-text': prop.style === 'italic' }">
            {{ prop.value }}
          </span>
        </template>
      </template>
    </DetailRow>
  </div>
</template>

<script>
  import DetailRow from "../DetailRow"

  export default {
    components: {
      DetailRow,
    },
    props: {
      properties: {
        type: Array,
        default: () => [],
      },
    },
  }
</script>

<style lang="scss" scoped>
  .wrap {
    margin-bottom: $base-spacing * 4;
  }
  .detailcheckicon {
    color: $green-shape;
    width: $base-spacing * 5;
    height: $base-spacing * 5;
    .cs-dark & {
      color: $green;
    }
  }

  .leafrating {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }

  .prop-name {
    position: relative;
  }

  .at-text {
    @extend %at-text;
  }

  .italic-text {
    font-style: italic;
  }

  .leaficon {
    color: $gray-200;
    width: $base-spacing * 4;
    height: $base-spacing * 4;
    &-active {
      color: $green-shape;
      .cs-dark & {
        color: $green;
      }
    }
  }
  .markicon {
    color: $red-shape;
    width: $base-spacing * 5;
    height: $base-spacing * 5;
    .cs-dark & {
      color: $red;
    }
  }
  .flashpoint {
    color: $yellow-text;
    font-weight: bold;
    .cs-dark & {
      color: $yellow;
    }
  }
  .percent {
    color: $gray-text;
    font-weight: bold;

    .cs-dark & {
      color: $gray-200;
    }
  }
  .property-table__candle-safe {
    font-weight: bold;
  }

  .property-table__prop-warning-required {
    color: $gray-text;
    font-weight: bold;
  }
</style>
