<template>
  <span>
    <nuxt-link
      v-for="(note, index) in notes"
      :key="note.name"
      :to="note.glossary_url"
      class="note-link"
    >
      {{ note.name }}<template v-if="index + 1 !== notes.length">, </template>
    </nuxt-link>
  </span>
</template>

<script>
  export default {
    props: {
      notes: {
        type: Array,
        default: () => [],
      },
    },
  }
</script>

<style scoped lang="scss">
  @import "~/assets/_variables";

  .note-link {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
</style>
